<template>
  <div>
    <v-card class="card-custom-shadow mb-5">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-autocomplete
              v-model="order.id_warehouse"
              :items="dcWarehouseOptions"
              @change="onFromChange"
              class="c-input-small"
              :label="$t('labels.from_dc_warehouse')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              v-model="toType"
              :items="toTypeOptions"
              class="c-input-small"
              :label="$t('labels.to')"
              @change="onToTypeChange"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              v-if="toType == 1"
              v-model="order.id_distributor"
              :items="distributorOptions"
              class="c-input-small"
              :label="$t('labels.select')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
            <v-autocomplete
              v-else
              v-model="order.id_warehouse_destination"
              :items="ecomWarehouseOptions"
              class="c-input-small"
              :label="$t('labels.select')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="order.note"
              class="c-input-small"
              :label="$t('labels.note')"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              color="success"
              block
              :disabled="isDisabledBtnSubmit"
              @click="createOrder"
            >
              {{ $t("labels.confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="card-custom-shadow">
          <v-card-text>
            <v-simple-table class="table-padding-2">
              <template v-slot:default>
                <thead class="v-data-table-header">
                  <tr>
                    <th>
                      <InputFilter
                        :label="$t('labels.barcode')"
                        :placeholder="$t('labels.barcode')"
                        name="customer_goods_barcode"
                        sort-name="customer_goods_barcode"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th>
                      <InputFilter
                        :label="$t('labels.name')"
                        :placeholder="$t('labels.name')"
                        name="name"
                        sort-name="name"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th>
                      <InputFilter
                        :label="$t('labels.goods_description')"
                        :placeholder="$t('labels.goods_description')"
                        name="description"
                        sort-name="description"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th>
                      <InputFilter
                        :label="$t('labels.size')"
                        :placeholder="$t('labels.size')"
                        name="size"
                        sort-name="size"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th>
                      <InputFilter
                        :label="$t('labels.unit')"
                        :placeholder="$t('labels.unit')"
                        name="unit"
                        sort-name="unit"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th class="text-center">
                      <InputFilterFromTo
                        :label="$t('labels.available_quantity_1')"
                        :placeholder="$t('labels.available_quantity_1')"
                        name="available"
                        sort-name="available"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th>
                      <SelectFilter
                        :label="$t('labels.image')"
                        :placeholder="$t('labels.image')"
                        name="image"
                        sort-name="url_images"
                        :options="imageOptions"
                        :sorting="filters.sort_by"
                        @onFilter="onFilterChange"
                        @onSort="onSortChange"
                      />
                    </th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody v-if="items.length > 0">
                  <tr
                    v-for="(item, index) in items"
                    :key="`g_${item.id}_${index}`"
                    class="text-center"
                  >
                    <td>{{ item.customer_goods_barcode }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.size }}</td>
                    <td>{{ item.unit }}</td>
                    <td>{{ formatNumber(item.available) }}</td>
                    <td>
                      <ImageViewer
                        v-if="item.url_images"
                        :url="item.url_images"
                      />
                    </td>
                    <td>
                      <template v-if="!selectedIds.includes(item.id)">
                        <v-btn
                          x-small
                          color="primary"
                          @click="selectItem(item)"
                        >
                          Chọn
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-icon small color="success">mdi-check-circle</v-icon>
                      </template>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7" class="text-center vertical-align-middle">
                      {{ $t("labels.not_yet_select_from_to") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="pt-3" v-if="items.length > 0">
              <v-pagination
                v-model="page"
                :length="totalPage"
                :total-visible="6"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="card-custom-shadow">
          <v-card-text>
            <v-simple-table class="table-padding-2">
              <template v-slot:default>
                <thead class="v-data-table-header">
                  <tr>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.barcode") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.name") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.goods_description") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.size") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.available_quantity_1") }}
                    </th>
                    <th class="text-center vertical-align-middle">
                      {{ $t("labels.quantity") }}
                    </th>
                    <th
                      class="text-center vertical-align-middle"
                      style="width: 75px"
                    >
                      <a
                        :href="templateLink"
                        target="_blank"
                        class="mb-1 v-btn v-btn--block v-btn--is-elevated v-btn--has-bg theme--light v-size--x-small primary"
                      >
                        {{ $t("labels.sample_file") }}
                      </a>
                      <v-btn
                        :disabled="!order.id_warehouse"
                        color="success"
                        x-small
                        class="mb-1"
                        block
                        @click="$refs.inputUploadFile.click()"
                      >
                        {{ $t("labels.upload") }}
                      </v-btn>
                      <input
                        type="file"
                        ref="inputUploadFile"
                        accept=".xlsx"
                        @change="onInputFileChange"
                        class="d-none"
                      />

                      <v-btn
                        v-if="selectItems.length > 0"
                        color="warning"
                        x-small
                        style="min-width: 20px; height: 20px; padding: 0"
                        @click="resetSelected"
                      >
                        <v-icon style="font-size: 14px">mdi-restore</v-icon>
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="selectItems.length > 0">
                  <tr
                    v-for="item in selectItems"
                    :key="`s${item.id}`"
                    class="text-center"
                  >
                    <td style="width: 105px">
                      {{ item.customer_goods_barcode }}
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.size }}</td>
                    <td>{{ formatNumber(item.available) }}</td>
                    <td style="width: 60px">
                      <v-text-field
                        class="c-input-number-xs"
                        v-model.number="item.quantity"
                        type="number"
                        @keyup="quantityChange"
                        dense
                        single-line
                        outlined
                        hide-details
                      ></v-text-field>
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        x-small
                        style="min-width: 20px; height: 20px; padding: 0"
                        @click="removeSelected(item)"
                      >
                        <v-icon style="font-size: 14px">mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="9" class="text-center vertical-align-middle">
                      {{ $t("labels.not_yet_select_goods") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash";
import { read, utils } from "xlsx";

export default {
  name: "OrderCreate",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  data: () => ({
    isLoading: false,
    fromToOptions: [],
    distributors: [],
    items: [],
    warehouses: [],
    selectItems: [],
    toType: 1,
    order: {
      id_warehouse: null,
      id_distributor: null,
      id_warehouse_destination: null,
      note: null,
    },
    page: 1,
    totalPage: 1,
    filters: {},
    imageOptions: [],
  }),
  computed: {
    isDisabledBtnSubmit() {
      if (
        !this.selectItems ||
        this.selectItems.length === 0 ||
        !this.order.id_warehouse ||
        (!this.order.id_distributor && !this.order.id_warehouse_destination)
      ) {
        return true;
      }
      return false;
    },
    templateLink() {
      return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_dieu_chuyen.xlsx`;
    },
    orderConverted() {
      return {};
    },
    selectedIds() {
      return [...this.selectItems].map((s) => s.id);
    },
    dcWarehouseOptions() {
      return [...this.warehouses]
        .filter((w) => w.type == 2)
        .map((w) => ({
          text: w.code_name,
          value: w.id,
        }));
    },
    ecomWarehouseOptions() {
      return [...this.warehouses]
        .filter((w) => w.type == 1)
        .map((w) => ({
          text: w.code_name,
          value: w.id,
        }));
    },
    distributorOptions() {
      return [...this.distributors].map((w) => ({
        text: w.name,
        value: w.id,
      }));
    },
    toTypeOptions() {
      return [
        {
          text: this.$t("labels.dc_distributor"),
          value: 1,
        },
        {
          text: this.$t("labels.ecom_warehouse"),
          value: 2,
        },
      ];
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    const { warehouses } = window.me;
    this.warehouses = [...warehouses];
    this.getDistributors();
  },
  methods: {
    selectItem(item) {
      item.quantity = 1;
      this.selectItems.push(item);
    },

    removeSelected(item) {
      this.selectItems = [...this.selectItems].filter((s) => s.id !== item.id);
    },

    resetSelected() {
      this.selectItems = [];
    },

    quantityChange: debounce(function () {
      this.selectItems = [...this.selectItems];
    }, 500),

    async createOrder() {
      if (this.isDisabledBtnSubmit) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/dc-order-create", {
          ...this.order,
          items: this.selectItems,
        });
        this.selectItems = [];
        this.order = {
          ...this.order,
          id_warehouse_destination: null,
          id_distributor: null,
        };
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.create_success"));
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates = (e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.validates) || {
          errRows: [],
          errTexts: [],
        };
        if (
          validates &&
          validates.errGroups &&
          validates.errGroups[0] &&
          validates.errGroups[0].errors
        ) {
          const errors = validates.errGroups[0].errors || [];
          this.$vToastify.error(errors.join("; "));
        } else {
          this.$vToastify.error(errMsg);
        }
      }
    },

    async onInputFileChange(e) {
      const mappingFields = {
        Barcode: "customer_goods_barcode",
        "Số lượngQuantity": "quantity",
      };
      const files = e.target.files,
        f = files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const values = utils.sheet_to_json(worksheet);
        const items = [...values].map((v) => {
          const item = {};
          Object.keys(v).forEach((i) => {
            const mapkey = i.replace(/\r?\n|\r/g, "");
            item[mappingFields[mapkey]] = `${v[i]}`.trim();
          });
          return item;
        });
        await this.syncGoodsData(items);
      };
      await reader.readAsArrayBuffer(f);
    },
    async syncGoodsData(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error(this.$t("messages.file_is_empty"));
      }
      if (items.length > 1000) {
        this.$vToastify.error(this.$t("messages.create_receipt_max_1000_rows"));
      }
      const duplicateBarcodes = [];
      const noDuplicateBarcodes = [];
      items.forEach((i) => {
        const nd = [...noDuplicateBarcodes].find(
          (n) => n === `${i.customer_goods_barcode}`
        );
        if (!nd) {
          noDuplicateBarcodes.push(`$${i.customer_goods_barcode}`);
        } else {
          duplicateBarcodes.push(`${i.customer_goods_barcode}`);
        }
      });
      if (duplicateBarcodes.length > 0) {
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateBarcodes.join("; "),
          })
        );
        return false;
      }

      const barcodes = [...items].map((i) => i.customer_goods_barcode);
      const { data } = await httpClient.post("/dc-get-goods-by-barcodes", {
        barcodes,
        id_warehouse: this.order.id_warehouse,
      });
      this.selectItems = [...items].map((i) => {
        const item = [...data].find(
          (d) => d.customer_goods_barcode == i.customer_goods_barcode
        );
        item.quantity = i.quantity;
        return item;
      });
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async getDistributors() {
      const { data } = await httpClient.post("/dc-distributor-all", {
        is_active: true,
        level: 1,
      });
      this.distributors = [...data];
    },
    onFromChange() {
      this.items = [];
      this.selectItems = [];
      this.getList();
    },
    onToTypeChange() {
      this.order = {
        ...this.order,
        id_warehouse_destination: null,
        id_distributor: null,
      };
    },
    getList: debounce(function () {
      if (!this.order.id_warehouse) {
        return false;
      }

      httpClient
        .post("/dc-order-create-goods-list", {
          ...this.filters,
          id_warehouse: this.order.id_warehouse,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 1000),
  },
};
</script>
